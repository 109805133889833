export default {
    'reports': 'Reports',
    'report': 'Report',
    'total_contacts': 'Total Contacts',
    'total_deals': 'Total Deals',
    'total_tickets': 'Total Tickets',

    'contact': 'Contact',
    'deal': 'Deal',
    'ticket': 'Ticket',

    'contact_page': 'Contact Page',
    'deal_page': 'Deal Page',
    'ticket_page': 'Ticket Page',

    'new_report': 'New Report',
    'report_saved': 'Report Saved',
    'new_widget': 'New Report Widget',
    'function': 'Function',
    'chart': 'Chart',
    'go_to': 'Go to',

    // preserved for default report items
    '_Ticket_Status': 'Ticket Status',
    '_Total_Contact': 'Total Contact',
    '_Total_Deals': 'Total Deals',
    '_Total_Tickets': 'Total Tickets',
    '_Deal_Revenue': 'Deal Revenue',
    '_Contacts_Per_Time': 'Contacts Per Time',
    '_Stages': 'Stages',
    '_Deal_By_Stage': 'Deal By Stage',

}
