export default {
    "settings": 'الإعدادات',
    "user_preferences": "تفضيلات المستخدم",
    "user_management": 'إدارة المستخدمين',
    "preferred_language": 'اللغة المفضلة',
    "date_and_time" : 'التاريخ والوقت',
    "default_homepage" : 'الصفحة الرئيسية',
    "these_preferences_will_only_apply_to_you" : 'سيتم تطبيق هذه التفضيلات عليك فقط.',
    "these_preferences_will_only_apply_to_the_current_account" : 'سيتم تطبيق هذه التفضيلات عليك لهذا الحساب فقط.',
    "these_preferences_will_be_applied_across_all_users_on_the_account" : 'سيتم تطبيق هذه التفضيلات على جميع المستخدمين لهذا الحساب ما لم يقوموا بتغييرها من إعداداتهم.',

    "whatsapp_pin": 'رقم واتساب السري',
    "whatsapp_pin_desc": 'إِذا كانت عملية التحقُّق على مرحلتين مُمَكَّنة لرقمِ هاتفِكَ مسبقًا، فأدخلها. وإلا، فأدخل رمزًا سريًا جديدًا ترغبُ في استخدامه.',
    "whatsapp_pin_why": "رقم واتساب السري إلزامي لتسجيل واتساب للأعمال، إذا نسيت رقم واتساب السري أو أخطأت في وضعه، فيمكنك تحديثه من <a href='https://business.facebook.com/wa/manage/phone-numbers/?phone_number={{phone_id}}' target='_blank'>اعدادات الواتساب في ميتا.</a>",

    "we_will_use_this_email_to_contact": "سوف نستخدم هذا البريد الإلكتروني للرد.",
    "subscription_price": "سعر الإشتراك",
    "prorated_price": "السعر التناسبي",
    "upgrade_workflow": "يتم تقسيم السعر بالتناسب بين خطتك الحالية وخطة الترقية، ولا يتم إضافة أي التزام جديد.",
    "upgrade_details": "تفاصيل الترقية",
    'by_clicking_upgrade_details': 'بالنقر على زر الترقية، فإنك توافق على دفع المجموع الكلي وشروط الإستعمال، وسيتم محاسبتك باستخدام طريقة الدفع الأخيرة.',

    "thank_you_for_choosing_alaaqat": 'شكرا لإختيارك علاقات',
    "we_are_preparing_yor_account": 'نحن نقوم بإعداد حسابك في الخلفية.\n' +
        'يستغرق هذا عادةً بضع دقائق فقط. يمكنك الاستمرار في إستخدام علاقات في هذه الأثناء.',

    "note_on_integrating_more_meta_accounts": "إذا كنت ترغب في إضافة حسابات ميتا أخرى ولكن شاشة موافقة ميتا تُغلق تلقائيًا، يمكنك الانتقال إلى <a href='https://www.facebook.com/settings/?tab=business_tools ' target='_blank'>أدوات الأعمال ميتا</a> في حسابك الشخصي لتغيير أذونات العلاقات.",

    "read_requirements_in_details": "اقرأ المتطلبات بالتفصيل في <a href='https://docs.alaaqat.com/ar/instagram' target='_blank'> كتيب المعلومات <a/>.",

    'whatsapp_about_desc': "يظهر هذا النص في الملف الشخصي للنشاط التجاري، أسفل صورة الملف الشخصي ورقم الهاتف وأزرار الاتصال.",

    // notifications
    "stored": 'مُخزنة',
    "stored_desc": 'حفظ الإشعار ليمكنك الرجوع إليه في أي وقت عن طريق النقر على أيقونة الجرس الموجود في الترويسة',
    "pop-up": 'مُنبثقة',
    "pop-up_desc": 'رسالة صغيرة عابرة تنبثق على الشاشة لإعلامك بوجود إشعار',
    "browser": 'المتصفح',
    "browser_desc": 'تقوم بلَفت انتباهك إلى الإشعارات حتى عندما لا تكون فعال على التطبيق، عن طريق إستخدام إشعارات جهازك.',
    "mobile": 'تطبيق الهاتف',
    "browser_notifications_request_permission_desc": 'لتلقي إشعارات المتصفح، يجب عليك منح إذن إشعارات المتصفح لعلاقات؛ انقر هنا لمنح الإذن.',
    "notification_permission_was_denied":'تم رفض إذن الإشعارات. لتلقي الإشعارات، يرجى تمكينها في إعدادات جهازك.',
    "test_message": 'مرحباً، أنا رسالة تجربة!',

    "upgrade_to_yearly_plan": "الترقية إلى خطة سنوية",
    "upgrade_to_yearly_plan_desc": "عند الترقية إلى الخطة السنوية، سيتم حساب القيمة المتبقية من الخطة الشهرية وخصمها من قيمة الفاتورة.",
    "preview_upgrade_to_yearly_plan": "معاينة الفاتورة",

    'disable_automatic_renew': 'تعطيل التجديد التلقائي',
    'disable_automatic_renew_desc': "إذا قمت بتعطيل التجديد التلقائي، فسيظل حسابك نشطًا حتى {{ activeDate }}.\n" +
        'إذا لم تقم بتمكين الاشتراك حتى ذلك الوقت، فسيتم إرجاع حسابك إلى حساب مجاني، وسوف تفقد إمكانية الوصول إلى الميزات المدفوعة، وسيتم تقييد أي قناة تتجاوز الحدود أيضًا.\n',
    'payments': 'الدفوعات',
    'enable_automatic_renew': 'تفعيل التجديد التلقائي',
    'your_plan_is_scheduled_to_cancel': 'من المقرر إلغاء خطتك في {{date}}.',
    'are_you_sure_you_want_to_cancel_automatic_renew': 'هل أنت متأكد من رغبتك في إلغاء التجديد التلقائي؟ يرجى ملاحظة أنه إذا قمت بالمتابعة، فسوف تفقد الوصول إلى الميزات المدفوعة عند انتهاء فترة الاشتراك الحالية، ما لم تقم بتجديد اشتراكك يدويًا قبل ذلك الوقت.',

    "stored_notifications": 'المُخزنة',
    "pop-up_notifications": 'المُنبثقة',
    "browser_notifications": 'المتصفح',
    "mobile_notifications": 'تطبيق الهاتف',
    "new_group" : 'مجموعة جديدة',
    'new_team':'انشاء فريق',
    'teams': 'الأفرقة',
    'user_invitation':'دعوة المستخدم',
    'disabled':'غير فعال',
    'active':'فعال',
    'inactive':'غير فعال',
    'invitation-sent':'الدعوة مرسلة',
    'all_teams':'جميع الفرق',
    'update_teams':'تحديث الأفرقة',
    'resend':'إعادة إرسال',
    'disable':'تعطيل',
    'activation':'تفعيل',
    'delete':'حذف',
    'edit_internal_name':'تعديل الاسم الداخلي',
    'internal_name':'الاسم الداخلي',

    'locale':'الثقافة',
    'locale_helper':'تؤثر الثقافة على كيفية عرض التطبيق للغة والتواريخ والأرقام والعملة بطريقة مألوفة ومناسبة لمنطقتك أو ثقافتك.',
    'ar':'عربي',
    'en':'الإنجليزية',
    'en_UK':'الإنجليزية البريطانية',
    'en_US':'الإنجليزية الأمريكية',
    'fr_FR':'الفرنسية (فرنسا)',

    'timeZone':'المنطقة الزمنية',
    'calendar':'تقويم',
    'gregory':'ميلادي',
    'islamic-civil':'هجري',
    'currency':'العملة',
    'time_format':'تنسيق الوقت',
    'hour12':'صباحًا/مساءً',
    'hour24':'24 ساعة',
    'browser_preferences':'تفضيلات المتصفح',
    'address':'العنوان',

    // security
    'two_factor_authentication':'المصادقة الثنائية',
    'enabled':'فعال',
    'enable':'تفعيل',
    'security_header':'عليك إدخال كلمة المرور الحالية لإظهار خيارات الأمان',
    'recovery_codes':'رموز الاسترداد',
    'recovery_codes_helper':'يستخدم رمز الاسترداد في القائمة لمرة واحدة.',
    'copy':'نسخ',
    'copied' : 'تم النسخ!',
    'recovery_codes_note_store':'احفظ هذه الرموز الاسترداد في مكان آمن.',
    'recovery_codes_notes': 'عند تمكين المصادقة الثنائية، ستحتاج إلى هذه الرموز الاسترداد إذا فقدت جهازك.' +
        ' في حالة عدم توفر جهازك أو رمز الاسترداد، يرجى الاتصال بـعلاقات لاستعادة حسابك.',
    'remove_two_factor_authentication': 'إزالة المصادقة ثنائية العامل',
    'add_two_factor_authentication': 'إضافة المصادقة ثنائية العامل',
    'show_recovery_codes': 'عرض رموز الاسترداد',
    'generate_new_codes': 'إنشاء رموز جديدة',
    "send_email_verification_notification": "سوف يتم إرسال إشعار التحقق عبر البريد الإلكتروني إلى {{email}}",
    "new_email": "البريد الإلكتروني الجديد",
    'change_email_header':'تغيير البريد الإلكتروني',
    'change_password_header':'تغيير كلمة المرور',
    'new_password':'كلمة مرور جديدة',

    'instagram_integration': 'تكامل إنستجرام',
    'instagram_integration_desc': 'قبل البدء، يجب عليك إعداد بعض العناصر الضرورية للتكامل مع إنستجرام. لا تقلق، قمنا بإنشاء قائمة من النصائح التي توجهك خطوة بخطوة.',
    'what_do_you_need': 'ما الذي تحتاجه:',
    'set_instagram_business': 'تعيين حسابك على إنستجرام كحساب تجاري',
    'connect_instagram_with_facebook': 'ربط حساب إنستجرام بصفحة على فيسبوك',
    'allow_access_to_messages': 'السماح بالوصول إلى الرسائل',
    'agree_on_instagram_terms': "بالمتابعة بتكامل إنستجرام، فإنك تقبل <a target='_blank' href='https://alaaqat.com/policy'>شروطنا بالإضافة إلى <a target='_blank' href='https://help.instagram.com/581066165581870/?helpref=uf_share'>شروط إنستجرام</a>.",

    'new_token': 'رمز جديد',
    'token_abilities': 'الإمكانيات',
    'revoke': 'إلغاء',
    'expires_at': 'ينتهي في',
    'copy_token': 'انسخ الرمز واحفظه في مكان آمن. لن تتمكن من عرضه بعد إغلاق هذه النافذة المنبثقة.',

    'api_desc': 'الرمز المُنتج هو للحساب الحالي فقط وصلاحيته 180 يوم، يجب تجديده يدوياً قبل ذلك. عند التجديد سيبقى الرمز القديم فعال لـ 30 دقيقة حتى يتيح لك الوقت لتغيره.',
    'rotate': 'تجديد',
    'you_cant_create_more_token': 'لا يمكنك إنشاء المزيد من الرموز، قم بإزالة الرموز القديمة أولاً.',
    "upgrade": "ترقية",
    "current_plan": "الخطة الحالية",
    "active_until": "نشط حتى",
    "automatic_renew": "تجديد تلقائي",

    'your_plan_is_active_until' : 'خطتك نشطة حتى',
    'and_scheduled_to_automatic_renew' : 'ومجدولة للتجديد التلقائي',
    'update_your_payment_method' : 'تحديث طرق الدفع',

    "Afrikaans": "أفريكانية",
    "Albanian": "الألبانية",
    "Arabic": "العربية",
    "Azerbaijani": "الأذربيجانية",
    "Bengali": "البنغالية",
    "Bulgarian": "البلغارية",
    "Catalan": "الكاتالونية",
    "Chinese_CHN": "الصينية (البر الرئيسي)",
    "Chinese_HKG": "الصينية (هونغ كونغ)",
    "Chinese_TAI": "الصينية (تايوان)",
    "Croatian": "الكرواتية",
    "Czech": "التشيكية",
    "Danish": "الدانماركية",
    "Dutch": "الهولندية",
    "English": "الإنجليزية",
    "English_UK": "الإنجليزية (المملكة المتحدة)",
    "English_US": "الإنجليزية (الولايات المتحدة)",
    "Estonian": "الإستونية",
    "Filipino": "الفلبينية",
    "Finnish": "الفنلندية",
    "French": "الفرنسية",
    "Georgian": "الجورجية",
    "German": "الألمانية",
    "Greek": "اليونانية",
    "Gujarati": "الجوجاراتية",
    "Hausa": "الهوسا",
    "Hebrew": "العبرية",
    "Hindi": "الهندية",
    "Hungarian": "الهنغارية",
    "Indonesian": "الإندونيسية",
    "Irish": "الأيرلندية",
    "Italian": "الإيطالية",
    "Japanese": "اليابانية",
    "Kannada": "الكانادا",
    "Kazakh": "الكازاخية",
    "Kinyarwanda": "الكينيارواندا",
    "Korean": "الكورية",
    "Kyrgyz_Kyrgyzstan": "القيرغيزية (قيرغيزستان)",
    "Lao": "اللاوية",
    "Latvian": "اللاتفية",
    "Lithuanian": "الليتوانية",
    "Macedonian": "المقدونية",
    "Malay": "الملايو",
    "Malayalam": "المالايالام",
    "Marathi": "الماراثية",
    "Norwegian": "النرويجية",
    "Persian": "الفارسية",
    "Polish": "البولندية",
    "Portuguese_BR": "البرتغالية (البرازيل)",
    "Portuguese_POR": "البرتغالية (البرتغال)",
    "Punjabi": "البنجابية",
    "Romanian": "الرومانية",
    "Russian": "الروسية",
    "Serbian": "الصربية",
    "Slovak": "السلوفاكية",
    "Slovenian": "السلوفينية",
    "Spanish": "الإسبانية",
    "Spanish_ARG": "الإسبانية (الأرجنتين)",
    "Spanish_SPA": "الإسبانية (إسبانيا)",
    "Spanish_MEX": "الإسبانية (المكسيك)",
    "Swahili": "السواحلية",
    "Swedish": "السويدية",
    "Tamil": "التاميل",
    "Telugu": "التيلوجو",
    "Thai": "التايلاندية",
    "Turkish": "التركية",
    "Ukrainian": "الأوكرانية",
    "Urdu": "الأردية",
    "Uzbek": "الأوزبكية",
    "Vietnamese": "الفيتنامية",
    "Zulu": "الزولو",
    'new_webhook': 'خطاف جديد',
    'webhooks':'خطافات الويب',
    'webhooks_desc':'تُستخدم الخطافات لإرسال الإشعارات إلى تطبيقات أخرى عند حدوث حدث معين.',
    'validated':'مُوَثَّق',
    'unvalidated':'غير موثق',
    'URL':'الرابط',
    'validate':'توثيق',
    'invalidate':'إلغاء التوثيق',
    'secret_key':'المفتاح السري',
    'update':'تحديث',
    'activate':'تفعيل',
    'deactivate':'إلغاء التفعيل',

    'update_webhook': 'تحديث الخطاف',
    "create_link": "انشاء الرابط",
    "create_link_message_desc": "أنشئ رابطًا خاصًا بك مع رسالة مُعبأة مسبقًا لتسهيل الوصول إليك.",

    'api_usage_history':"سجل استخدام الواجهة البرمجية",
    'api_usage_history_desc':"تُظهر السجلات الأخيرة لاستخدام الواجهة البرمجية لحسابك في اخر 7 ايام.",
    'key_name':"اسم المفتاح",
    'api_url':"رابط الواجهة البرمجية",
    'payload':"محتوى الطلب",
    'request_method':"طريقة الطلب",
    'request_status':"حالة الطلب",
    'business_links': 'العناوين الإلكترونية المرتبطة بالنشاط التجاري',
    'urls_associated_with_business': 'على سبيل المثال، موقع ويب أو صفحة فيسبوك أو انستغرام.',
    'website_may_block_you': 'هذه الروابط إختيارية، في حال إدخالها، ستقوم واتس اب بالتحقق من هذه الروابط، وقد يتم حظرك إذا كانت هذه الروابط لا تحتوي على معلومات كافية حول عملك.',

    'default_route': 'الصفحة الرئيسية',
    'route_reports': 'التقارير',
    'route_contacts': 'جهات الإتصال',
    'route_deals': 'الصفقات',
    'route_tickets': 'تذاكر الدعم',
    'route_conversations.inbox': 'المحادثات',
}
