// noinspection AllyPlainJsInspection

export default {
    "settings": 'Settings',
    "user_preferences": "User Preferences",
    "user_management": 'Users Management',
    "preferred_language": 'Preferred language',
    "date_and_time" : 'Date & Time',
    "default_homepage" : 'Default homepage',
    "these_preferences_will_only_apply_to_you" : 'These preferences will only be applied to you.',
    "these_preferences_will_only_apply_to_the_current_account" : 'These preferences affect the current account only.',
    "these_preferences_will_be_applied_across_all_users_on_the_account" : 'These preferences will apply to all users of the current account unless they change them from their preferences',

    "whatsapp_pin": 'WhatsApp PIN',
    "whatsapp_pin_desc": 'If your phone number already has two-step verification enabled, enter your PIN, otherwise enter a new PIN you want to use.',
    "whatsapp_pin_why": "WhatsApp PIN is required for Whatsapp Business phone registration. If you forget or misplace your PIN, you can update it from <a href='https://business.facebook.com/wa/manage/phone-numbers/?phone_number={{phone_id}}' target='_blank'>Meta WhatsApp Manager.</a>",

    "we_will_use_this_email_to_contact": "We will use this email to reply back.",
    "subscription_price": "Subscription price",
    "upgrade_workflow": "Price is prorated between your current plan and the upgrade plan, no new commitment is added.",
    "prorated_price": "Prorated price",
    "upgrade_details": "Upgrade details",
    'by_clicking_upgrade_details': 'By clicking upgrade, you are accepting to pay the grand total price and our terms of use, you will be charged using the last payment method.',

    "thank_you_for_choosing_alaaqat": 'Thank you for choosing Alaaqat',
    "we_are_preparing_yor_account": 'We\'re preparing your account in the background.\n' +
        '            This typically takes just a few minutes. Feel free to keep using Alaaqat in the meantime.',

    "note_on_integrating_more_meta_accounts": "If you want to add more Meta accounts but the Meta consent screen automatically closes, you can go to your personal account <a href='https://www.facebook.com/settings/?tab=business_tools' target='_blank'>Meta business tools</a> to change Alaaqat's permissions.",
    "read_requirements_in_details": "Read the requirements in detail <a href='https://docs.alaaqat.com/en/instagram' target='_blank'>in the documentation</a>.",

    'whatsapp_about_desc': "This text appears in the business's profile, beneath its profile image, phone number, and contact buttons.",
    // notifications
    "stored": 'Stored',
    "stored_desc": 'Save the notification so you can return to it at any time by clicking on the bell icon in the header',
    "pop-up": 'Pop-up',
    "pop-up_desc": 'A small, transient message that pop-up on the screen to inform you of a notification',
    "browser": 'Browser',
    "browser_desc": 'Bring notifications to your attention even when you are not using the application by using your device notification.',
    "mobile": 'Mobile',
    "browser_notifications_request_permission_desc": 'To receive browser notifications, you must give Alaaqat browser notification permission; click here to give permission.',
    "notification_permission_was_denied": 'Notification permission denied. To receive notifications, please enable them in your device app settings.',
    "test_message": 'Hey, I\'m a test message',

    "upgrade_to_yearly_plan": "Upgrade to yearly plan",
    "upgrade_to_yearly_plan_desc": "When you upgrade to the annual plan, the remaining value of the monthly plan will be calculated and deducted from the bill value.",
    "preview_upgrade_to_yearly_plan": "Preview invoice",

    "stored_notifications": 'Stored Notifications',
    "pop-up_notifications": 'Pop-up Notifications',
    "browser_notifications": 'Browser Notifications',
    "mobile_notifications": 'Mobile Notifications',
    "new_group" : 'New Group',
    'new_team': 'New Team',
    'teams': 'Teams',
    'user_invitation':'User Invitation',
    'disabled':'Disabled',
    'active':'Active',
    'inactive':'Inactive',
    'invitation-sent':'Sent invitation',
    'all_teams':'All Teams',
    'update_teams':'Update Teams',
    'resend':'Resend',
    'disable':'Disable',
    'activation':'Activation',
    'delete':'Delete',
    'edit_internal_name':'Edit Internal Name',
    'internal_name':'Internal Name',
    'locale':'Locale',
    'locale_helper':'Locale affects how an application displays language, dates, numbers, and currency in a way that\'s familiar and appropriate to your region or language.',
    'ar':'Arabic',
    'en':'English',
    'en_UK':'English (United Kingdom)',
    'en_US':'English (United States)',
    'fr_FR':'French (France)',

    'timeZone':'Time Zone',
    'calendar':'Calendar',
    'gregory':'Gregory',
    'islamic-civil':'Islamic',
    'currency':'Currency',
    'time_format':'Time Format',
    'hour12':'12-Hour Clock',
    'hour24':'24-Hour Clock',
    'browser_preferences':'Browser preferences',
    'address':'Address',

    'instagram_integration': 'Instagram integration',
    'instagram_integration_desc': 'Before you start, you should prepare a few elements that are required to integrate with Instagram. Don’t worry, we created list of tips that guides you step by step.',
    'what_do_you_need': 'What do you need:',
    'set_instagram_business': 'Set your account to Instagram Business',
    'connect_instagram_with_facebook': 'Connect Instagram account with a Facebook fan page',
    'allow_access_to_messages': 'Allow access to messages',
    'agree_on_instagram_terms': "By proceeding with the Instagram integration, you are accepting to <a target='_blank' href='https://alaaqat.com/policy'>our terms as well as <a href='https://help.instagram.com/581066165581870/?helpref=uf_share' target='_blank'>Instagram's terms</a>.",

    'api_desc': 'The generated token is for current account, valid for 180 days, and must be renewed manually before that. Upon renewal, the old token will remain active for 30 minutes, giving you time to change it.',
    'rotate': 'Rotate',
    'you_cant_create_more_token': "You can't create more tokens, remove old ones first.",

    'your_plan_is_active_until' : 'Your plan is active until',
    'and_scheduled_to_automatic_renew' : 'and is schedule to automatic renew',
    'update_your_payment_method' : 'Update your payment method',

    // security
    'two_factor_authentication':'Two-Factor Authentication',
    'enable':'Enable',
    'enabled':'Enabled',
    'disables':'Disables',
    'security_header':'Input your current password to show the security options',
    'recovery_codes':'Recovery Codes',
    'recovery_codes_helper':'Recovery code is a one-time use token.',
    'copy':'Copy',
    'copied':'Copied!',
    'recovery_codes_note_store':'Save these recovery codes in a secure place.',
    'recovery_codes_notes': 'With 2FA enabled for your account, you will need these backup codes if you ever lose your device. ' +
        'If you don\'t have your device or a backup code, please contact Alaaqat to recover your account.',
    'remove_two_factor_authentication': 'Remove Two-Factor Authentication',
    'add_two_factor_authentication': 'Add Two-Factor Authentication',
    "send_email_verification_notification": "Send email verification notification to {{email}}",
    'show_recovery_codes': 'Show Recovery Codes',
    'generate_new_codes': 'Generate New Codes',
    "new_email": "New Email",
    "change_email_header": "Change Email",
    "change_password_header": "Change Password",
    "new_password": "New Password",

    'new_token': 'New Token',
    'token_abilities': 'Abilities',
    'revoke': 'Revoke',
    'expires_at': 'Expires at',
    'copy_token': 'Copy the token and store it in a safe place, you will NOT be able to view it after closing this modal.',
    'upgrade': 'Upgrade',
    'current_plan': 'Current plan',
    'active_until': 'Active until',
    'automatic_renew': 'Automatic renew',

    'disable_automatic_renew': 'Disable automatic renewal',
    'disable_automatic_renew_desc': 'If you disable automatic renewal, your account will still be active until {{ activeDate }},\n' +
        'if you haven\'t enabled the subscription until that time, your account will be downgraded to free account, and you will lose access to paid features, any channel exceed limits will be restricted as well.',
    'enable_automatic_renew': 'Enable automatic renewal',

    'payments': 'Payments',
    'your_plan_is_scheduled_to_cancel': 'Your plan is scheduled to be canceled at {{date}}.',
    'are_you_sure_you_want_to_cancel_automatic_renew': 'Are you sure you want to cancel your automatic renewal? Please note that if you proceed, you will lose access to the paid features once the current subscription period ends, unless you manually renew your subscription before that time.',

    "Afrikaans" : "Afrikaans",
    "Albanian" : "Albanian",
    "Arabic" : "Arabic",
    "Azerbaijani" : "Azerbaijani",
    "Bengali" : "Bengali",
    "Bulgarian" : "Bulgarian",
    "Catalan" : "Catalan",
    "Chinese_CHN" : "Chinese_CHN",
    "Chinese_HKG" : "Chinese_HKG",
    "Chinese_TAI" : "Chinese_TAI",
    "Croatian" : "Croatian",
    "Czech" : "Czech",
    "Danish" : "Danish",
    "Dutch" : "Dutch",
    "English" : "English",
    "English_UK" : "English_UK",
    "English_US" : "English_US",
    "Estonian" : "Estonian",
    "Filipino" : "Filipino",
    "Finnish" : "Finnish",
    "French" : "French",
    "Georgian" : "Georgian",
    "German" : "German",
    "Greek" : "Greek",
    "Gujarati" : "Gujarati",
    "Hausa" : "Hausa",
    "Hebrew" : "Hebrew",
    "Hindi" : "Hindi",
    "Hungarian" : "Hungarian",
    "Indonesian" : "Indonesian",
    "Irish" : "Irish",
    "Italian" : "Italian",
    "Japanese" : "Japanese",
    "Kannada" : "Kannada",
    "Kazakh" : "Kazakh",
    "Kinyarwanda" : "Kinyarwanda",
    "Korean" : "Korean",
    "Kyrgyz_Kyrgyzstan" : "Kyrgyz_Kyrgyzstan",
    "Lao" : "Lao",
    "Latvian" : "Latvian",
    "Lithuanian" : "Lithuanian",
    "Macedonian" : "Macedonian",
    "Malay" : "Malay",
    "Malayalam" : "Malayalam",
    "Marathi" : "Marathi",
    "Norwegian" : "Norwegian",
    "Persian" : "Persian",
    "Polish" : "Polish",
    "Portuguese_BR" : "Portuguese_BR",
    "Portuguese_POR" : "Portuguese_POR",
    "Punjabi" : "Punjabi",
    "Romanian" : "Romanian",
    "Russian" : "Russian",
    "Serbian" : "Serbian",
    "Slovak" : "Slovak",
    "Slovenian" : "Slovenian",
    "Spanish" : "Spanish",
    "Spanish_ARG" : "Spanish_ARG",
    "Spanish_SPA" : "Spanish_SPA",
    "Spanish_MEX" : "Spanish_MEX",
    "Swahili" : "Swahili",
    "Swedish" : "Swedish",
    "Tamil" : "Tamil",
    "Telugu" : "Telugu",
    "Thai" : "Thai",
    "Turkish" : "Turkish",
    "Ukrainian" : "Ukrainian",
    "Urdu" : "Urdu",
    "Uzbek" : "Uzbek",
    "Vietnamese" : "Vietnamese",
    "Zulu" : "Zulu",
    'new_webhook':'New Webhook',
    'webhook':'Webhook',
    'webhooks':'Webhooks',
    'webhook_desc':'Webhooks allow you to send real-time notifications to your server when specific events occur in Alaaqat.',
    'validated':'Validated',
    'unvalidated':'Unvalidated',
    'URL':'URL',
    'validate':'Validate',
    'invalidate':'Invalidate',
    'secret_key':'Secret Key',
    'update':'Update',
    'activate':'Activate',
    'deactivate':'Deactivate',

    'update_webhook':'Update Webhook',
    "createLinkMessage": "Create your own link with a pre-filled message",

    'api_usage_history':'API Usage History',
    "api_usage_history_desc": "Displays the recent usage logs of your account's API in the last 7 days.",
    "key_name": "Key Name",
    "api_url": "API URL",
    "payload": "Payload",
    "request_method": "Request Method",
    "request_status": "Request Status",
    'business_links': 'The URLs associated with the business',
    'urls_associated_with_business': 'For instance, a website, Facebook Page, or Instagram.',
    'website_may_block_you': 'These links are optional, if they are provided, WhatsApp will verifies them. you may get banned if these links do not contain enough information about your business.',

    'default_route': 'Main Page',
    'route_reports': 'Reports',
    'route_contacts': 'Contacts',
    'route_deals': 'Deals',
    'route_tickets': 'Tickets',
    'route_conversations.inbox': 'Conversations',
}
