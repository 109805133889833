import i18next from 'i18next';
import type {i18n as i18nType} from 'i18next';

import arSettings from "@lang/ar/settings";
import arCommon from "@lang/ar/common";
import arAuth from "@lang/ar/auth";
import arConversation from "@lang/ar/conversations";
import arValidations from "@lang/ar/validations";
import arObjects from "@lang/ar/objects";
import arAccounts from "@lang/ar/accounts";
import arIndustries from "@lang/ar/industries";
import arEmoji from "@lang/ar/emoji";
import arProperties from "@lang/ar/properties";
import arTimes from "@lang/ar/times";
import arNotifications from "@lang/ar/notifications";
import arVertical from "@lang/ar/vertical";
import arWorkflows from "@lang/ar/workflows";
import arReports from "@lang/ar/reports";

import enSettings from "@lang/en/settings";
import enCommon from "@lang/en/common";
import enAuth from "@lang/en/auth";
import enConversation from "@lang/en/conversations";
import enValidations from "@lang/en/validations";
import enObjects from "@lang/en/objects";
import enAccounts from "@lang/en/accounts";
import enIndustries from "@lang/en/industries";
import enEmoji from "@lang/en/emoji";
import enProperties from "@lang/en/properties";
import enTimes from "@lang/en/times";
import enNotifications from "@lang/en/notifications";
import enWorkflows from "@lang/en/workflows";
import enReports from "@lang/en/reports";

import LanguageDetector from 'i18next-browser-languagedetector';
import arabicFirst from "./detectors/arabicFirst";
import enVertical from "@lang/en/vertical";

export const locales = [
    'en', 'ar'
];

export type LocaleType = 'en' | 'ar'
export type LocalizedText = {
    [key in LocaleType] : string
}

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

export interface i18nModified extends i18nType {
    language: LocaleType
}

const detector = new LanguageDetector(null, {
    // order and from where user language should be detected
    order: [ 'cookie', 'htmlTag', 'arabicFirst', 'navigator'],
    lookupCookie: 'lang',
    cookieDomain: import.meta.env.VITE_COOKIES_DOMAIN,
    // lookupLocalStorage: 'i18nextLng',

    // don't cache on the frontend, caching are done on the backend
    caches: [],
});
detector.addDetector(arabicFirst);

i18next.use(detector).init({
    debug: false,
    fallbackLng: 'ar',
    supportedLngs: locales,
    ns: [
        'common','settings', 'auth', 'conversations',
        'validations', 'accounts', 'contacts', 'industries', 'emoji',
        'properties', 'times', 'notifications','vertical', 'workflows',
        'reports'
    ],
    nonExplicitSupportedLngs: true,
    lowerCaseLng: true,
    cleanCode: true,
    returnNull:false,
    interpolation: {
        escapeValue:false,
    },
    resources: {
        en: {
            common: enCommon,
            settings: enSettings,
            auth: enAuth,
            conversations: enConversation,
            validations: enValidations,
            accounts: enAccounts,
            objects: enObjects,
            industries: enIndustries,
            emoji: enEmoji,
            properties: enProperties,
            times: enTimes,
            notifications: enNotifications,
            vertical:enVertical,
            workflows: enWorkflows,
            reports: enReports
        },
        ar: {
            common: arCommon,
            settings: arSettings,
            auth: arAuth,
            conversations: arConversation,
            validations: arValidations,
            accounts: arAccounts,
            objects: arObjects,
            industries: arIndustries,
            emoji: arEmoji,
            properties: arProperties,
            times: arTimes,
            notifications: arNotifications,
            vertical:arVertical,
            workflows: arWorkflows,
            reports: arReports
        }
    }
});

export const i18n = i18next as i18nModified;

export const isRtl = i18n.dir() === 'rtl';

export function objectT(obj: LocalizedText)
{
    return obj[i18n.language] ?? obj['ar'] ?? obj['en'] ?? ''
}

export default i18next.t
