import {Temporal} from "temporal-polyfill";
import {useUser} from "@js/stores/User";
import {i18n} from "@lang/t";

export type HelperDateTimeUnits = "year" | "month" | "week" | "day" | "hour" | "minute" | "second"

export class DateHelper
{
    public zoned: Temporal.ZonedDateTime;
    public timezone: Temporal.TimeZone | Temporal.TimeZoneProtocol;

    constructor(public epochNanoseconds: bigint|Temporal.ZonedDateTime) {
        let userStore = useUser();

        this.timezone = Temporal.TimeZone.from(userStore.auth.timeZone)

        this.zoned = epochNanoseconds instanceof Temporal.ZonedDateTime ? epochNanoseconds : new Temporal.ZonedDateTime(
            this.epochNanoseconds as bigint,
            userStore.auth.timeZone,
            userStore.auth.calendar
        )
    }

    static now()
    {
        let userStore = useUser();
        const timeZone = Temporal.TimeZone.from(userStore.auth.timeZone)
        const calendar = Temporal.Calendar.from(userStore.auth.calendar)

        let zonedDateTime = Temporal.Now.instant().toZonedDateTime({timeZone, calendar})

        return new DateHelper(zonedDateTime)
    }

    static fromIso8601String(isoDateString: string)
    {
        let userStore = useUser();

        const dateTime = Temporal.Instant.from(isoDateString);
        const timeZone = Temporal.TimeZone.from(userStore.auth.timeZone)

        const calendar = Temporal.Calendar.from(userStore.auth.calendar)
        const zonedDateTime = dateTime.toZonedDateTime({timeZone, calendar});

        return new DateHelper(zonedDateTime)
    }

    static fromDateTime(dateTime: string)
    {
        let userStore = useUser();

        let plainDateTime = Temporal.PlainDateTime.from(dateTime);
        const timeZone = Temporal.TimeZone.from(userStore.auth.timeZone)
        const calendar = Temporal.Calendar.from(userStore.auth.calendar)
        const zonedDateTime = plainDateTime.withCalendar(calendar)
            .toZonedDateTime(timeZone);

        return new DateHelper(zonedDateTime)
    }

    static fromDate(date: string)
    {
        let userStore = useUser();

        let plainDateTime = Temporal.PlainDate.from(date);
        const timeZone = Temporal.TimeZone.from(userStore.auth.timeZone)
        const calendar = Temporal.Calendar.from(userStore.auth.calendar)
        const zonedDateTime = plainDateTime.withCalendar(calendar)
            .toZonedDateTime(timeZone);

        return new DateHelper(zonedDateTime)
    }

    toDateString()
    {
        let userStore = useUser();
        return this.zoned.toLocaleString(userStore.auth.locale, {
            day: "2-digit", month: "short", year: 'numeric'
        })
    }

    toDateTimeString()
    {
        let userStore = useUser();
        return this.zoned.toLocaleString(userStore.auth.locale, {
            day: "2-digit", month: "2-digit", year: 'numeric',
            hour12:userStore.auth.hour12, hour: '2-digit', minute: '2-digit'
        })
    }

    /**
     *
     * @param timeDirection - indicate if that time is in the future or the past, -1 is past, 1 is future.
     */
    toRelativeTime(timeDirection = -1)
    {
        const locale = i18n.language;

        const formatter = new Intl.RelativeTimeFormat(locale, { style: 'short' });

        let unit = this.getDynamicUnit();
        let amount = this.untilNow(unit);

        return formatter.format(amount * timeDirection, unit);
    }

    untilNow(unit: HelperDateTimeUnits)
    {
        const duration = this.zoned.toInstant().until(Temporal.Now.instant())

        return Math.ceil(duration.total({ unit: unit, relativeTo:this.zoned}));
    }

    /**
     *
     * @param timeDirection - indicate if that time is in the future or the past, -1 is past, 1 is future.
     * @param unit
     * @param threshold
     */
    toRelativeIfNewerThan(timeDirection = -1, unit: HelperDateTimeUnits|null = null, threshold = 60)
    {
        unit = unit ?? this.getDynamicUnit();

        const locale = i18n.language;

        const formatter = new Intl.RelativeTimeFormat(locale, { style: 'short' });

        let amount = this.untilNow(unit);

        if(threshold < amount) {
            return this.toDateTimeString()
        }

        return formatter.format(amount * timeDirection, unit);
    }

    getDynamicUnit(): HelperDateTimeUnits
    {
        const duration = this.zoned.toInstant().until(Temporal.Now.instant())
        let seconds = Math.ceil(duration.total({ unit: 'second'}));

        if(seconds > 2463840 * 28) {
            return 'year'
        }

        if(seconds > 2463840) {
            return 'month'
        }

        if(seconds > 84960) {
            return 'day'
        }

        if(seconds > 3540) {
            return 'hour'
        }

        if(seconds > 59) {
            return 'minute';
        }

        return 'second'
    }

    toIsoDate(){
        return Temporal.Instant.fromEpochMilliseconds(this.zoned.epochMilliseconds).toString()
    }

    static fromPlainDate(plainDate: Temporal.PlainDate)
    {
        let userStore = useUser();

        const timeZone = Temporal.TimeZone.from(userStore.auth.timeZone)

        const calendar = Temporal.Calendar.from(userStore.auth.calendar)
        const zonedDateTime =  plainDate.withCalendar(calendar).toZonedDateTime({timeZone: timeZone});

        return new DateHelper(zonedDateTime)
    }
}
