export default {
    "reports": "التقارير",
    "report": "التقرير",
    "total_contacts": "إجمالي جهات الاتصال",
    "total_deals": "إجمالي الصفقات",
    "total_tickets": "إجمالي التذاكر",
    "contact": "جهة اتصال",
    "deal": "صفقة",
    "ticket": "تذكرة",

    'contact_page': 'صفحة جهات الإتصال',
    'deal_page': 'صفحة الصفقات',
    'ticket_page': 'صفحة التذاكر',

    "new_report": "تقرير جديد",
    "report_saved": "تم حفظ التقرير",
    "new_widget": "عنصر تقرير جديد",
    "function": "وظيفة",
    "chart": "رسم بياني",
    'go_to': 'الذهاب لـ',

    // preserved for default report items
    "_Ticket_Status": "حالة التذكرة",
    "_Total_Contact": "إجمالي جهات الاتصال",
    "_Total_Deals": "إجمالي الصفقات",
    "_Total_Tickets": "إجمالي التذاكر",
    "_Deal_Revenue": "إيرادات الصفقات",
    "_Contacts_Per_Time": "جهات الاتصال حسب الوقت",
    "_Stages": "المراحل",
    "_Deal_By_Stage": "الصفقات حسب المرحلة"
}
